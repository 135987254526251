<template>
  <div />
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['AppActiveUser']),
  },

  created() {
    this.syncData()
  },

  mounted() {
    this.$vs.loading()
  },

  destroyed() {
    this.$vs.loading.close()
  },

  methods: {
    async syncData() {
      const { code, scope, error } = this.$route.query

      if (error) {
        this.$toast.error(this.$t('error.C_103'))

        if (this.AppActiveUser && this.AppActiveUser.uid) {
          this.goTo('user-settings', { id: this.AppActiveUser.uid })
        } else {
          this.goTo('user-settings')
        }
      } else if (!code || !this.AppActiveUser || !this.AppActiveUser.uid) {
        this.goTo('user-settings')
      } else {
        try {
          const calendarGetAccessToken = this.$functions.httpsCallable('calendarGetAccessToken')

          await calendarGetAccessToken({ userId: this.AppActiveUser.uid, code, scope })

          this.successExchangeToken()
        } catch (error) {
          this.catchExpiredToken()
        }
      }
    },

    goTo(route, params = {}) {
      this.$router.push({ name: route, params })
    },

    successExchangeToken() {
      this.$toast.success(this.$t('calendar.calendarHasBeenSync'))

      this.goTo('user-settings', { from: 'verify-sync-calendar' })
    },

    catchExpiredToken() {
      this.$toast.error(this.$t('error.C_104'))

      this.goTo('user-settings')
    },
  },
}
</script>
